<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search pl-50">
          <!-- style="display: block; padding: 1.286rem; height: auto" -->
          <div class="d-flex align-items-center w-100">
            <!-- <div
              class="sidebar-profile-toggle mr-50"
              v-if="$store.state.rights['apps-chat']"
            >
              <feather-icon
                size="24"
                icon="ArrowLeftIcon"
                v-b-tooltip.hover.top
                title="Back"
                @click="backChat"
                class="cursor-pointer"
              />
            </div> -->
            <!-- Search -->
            <b-input-group class="input-group-merge w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input
                v-if="showMembers"
                v-model="memberQuery"
                placeholder="Search member"
              />
              <b-form-input
                v-else
                v-model="searchQuery"
                placeholder="Search..."
              />
            </b-input-group>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="EditIcon"
              size="30"
              v-b-tooltip.hover.top
              title="New Message"
              @click="$emit('open-new')"
            />
            <feather-icon
              v-if="$store.state.rights['outbox']"
              class="ml-1 cursor-pointer"
              icon="MailIcon"
              size="30"
              v-b-tooltip.hover.top
              title="Outbox"
              @click="openOutbox()"
            />
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <!-- <h4 class="chat-list-title" v-if="filteredChatsContacts.length == 0">
          Chats
        </h4> -->

        <!-- v-else -->
        <vue-perfect-scrollbar
          v-if="showMembers"
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
          @ps-scroll-y="scrollHandle"
        >
          <div
            class="chat-list-title d-flex align-items-center justify-content-between"
          >
            <h4 class="text-primary">
              <feather-icon
                icon="ArrowLeftIcon"
                size="18"
                class="cursor-pointer"
                @click="goBack"
                v-b-tooltip.hover.top
                title="Back"
              />
              Billing
              <!-- <b-button
              variant="outline-primary"
              class="btn-icon"
              @click="goBack"
            >
              <feather-icon icon="ArrowLeftIcon" />
              <span>Back</span> 
            </b-button> -->
            </h4>
            <b-badge
              v-if="rights.add"
              variant="light-primary"
              class="cursor-pointer"
              @click="$emit('add-member', filteredMembers.group)"
            >
              <feather-icon icon="PlusIcon" />
              Upload
            </b-badge>
          </div>

          <!-- current group -->
          <ul class="chat-users-list chat-list media-list">
            <chat-contact :user="filteredMembers.group" tag="li" />
          </ul>

          <h4 class="chat-list-title">
            Bills ({{ filteredMembers.contacts.length }})
          </h4>

          <!-- members list -->
          <ul class="chat-users-list chat-list media-list">
            <div v-if="dataLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>

            <chat-contact
              v-else
              v-for="(contact, ind) in searchedMembers.slice(
                0,
                count + sliceSize
              )"
              :key="'mem' + String(ind)"
              :user="contact"
              tag="li"
              is-member
              @edit-member="(obj) => $emit('edit-member', obj)"
              @delete-member="(id) => $emit('delete-member', id)"
              :rights="rights"
            />
            <!-- @click="$emit('open-chat', contact)" -->
          </ul>
        </vue-perfect-scrollbar>

        <vue-perfect-scrollbar
          v-else
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >
          <!-- Chats Title -->
          <div
            class="chat-list-title d-flex align-items-center justify-content-between"
          >
            <h4 class="text-primary">Billings</h4>
            <b-badge
              v-if="rights.add"
              variant="light-primary"
              class="cursor-pointer"
              @click="$emit('open-group', 0)"
            >
              <feather-icon class="cursor-pointer" icon="PlusIcon" />
              Upload
            </b-badge>
          </div>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <div v-if="dataLoading">
              <vue-content-loading
                :width="300"
                :height="60"
                class="ml-1"
                v-for="n in 5"
                :key="n"
              >
                <circle cx="30" cy="30" r="23" />
                <rect x="75" y="13" rx="3" ry="3" width="200" height="13" />
                <rect x="75" y="34" rx="3" ry="3" width="150" height="10" />
              </vue-content-loading>
            </div>
            <chat-contact
              v-else
              v-for="(contact, ind) in filteredChatsContacts"
              :key="'chat' + String(ind)"
              :user="contact.group"
              tag="li"
              is-chat-contact
              @edit-group="(obj) => $emit('open-group', obj)"
              @delete-group="(id) => $emit('del-group', id)"
              :rights="rights"
              @click="$emit('open-chat-new', contact)"
            />
            <!-- @click="openMembers(contact.group.id)" -->
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BAvatar,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
  BModal,
  BFormCheckbox,
  BRow,
  BCol,
  BForm,
  VBTooltip,
  BSidebar,
  BSpinner,
  BButton,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, computed } from "@vue/composition-api";
import ChatContact from "./ChatContact.vue";
import UserProfileSidebar from "./UserProfileSidebar.vue";
import { mapActions, mapGetters } from "vuex";

import { VclBulletList, VueContentLoading } from "vue-content-loading";

export default {
  components: {
    // BSV
    VclBulletList,
    VueContentLoading,
    BSidebar,
    VBTooltip,
    BSpinner,
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BRow,
    BCol,
    vSelect,
    BFormGroup,
    BBadge,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    dataLoading: {
      type: Boolean,
      required: true,
    },
    rights: {
      type: Object,
      required: true,
    },
    chatsContacts: {
      type: Array,
      required: true,
    },
    allGroups: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  created() {},
  data() {
    return {
      request: false,
      visibility: false,
      chatData: {},
      delayOptions: [
        { text: "5 seconds", value: 5 },
        { text: "6 seconds", value: 6 },
        { text: "7 seconds", value: 7 },
        { text: "8 seconds", value: 8 },
        { text: "9 seconds", value: 9 },
        { text: "10 seconds", value: 10 },
        { text: "11 seconds", value: 11 },
        { text: "12 seconds", value: 12 },
      ],
      currentMembers: [],
    };
  },
  emits: ["close-chat"],
  setup(props, ctx) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    const resolveChatContact = (userId) =>
      props.contacts.find((contact) => contact.id === userId);

    // Search Query
    const searchQuery = ref("");

    // const searchFilterFunction = (contact) =>
    //   contact.name.toLowerCase().includes(searchQuery.value.toLowerCase());
    // const filteredChatsContacts = computed(() =>
    //   props.chatsContacts.filter(searchFilterFunction)
    // );

    const searchFilterFunction = (obj) =>
      obj.contacts.some(
        (el) =>
          el.regNum.toString().includes(searchQuery.value.toLowerCase()) ||
          obj.group.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );

    const filteredChatsContacts = computed(() =>
      props.allGroups.filter(searchFilterFunction)
    );

    const gID = ref(0);
    const showMembers = ref(false);

    const filteredMembers = computed(() =>
      props.allGroups.find((el) => el.group.id == gID.value)
    );

    const memberQuery = ref("");
    const sliceSize = ref(200);
    const count = ref(0);

    const searchedMembers = computed(() =>
      filteredMembers.value.contacts.filter(
        (el) => el.regNum.toString().includes(memberQuery.value.toLowerCase())
        // || el.name.toLowerCase().includes(memberQuery.value.toLowerCase())
      )
    );

    const scrollHandle = (evt) => {
      let pos = evt.target.childNodes[3].childNodes[0].offsetTop;
      // console.log(pos);
      if (pos > 700) {
        count.value += sliceSize.value;

        // console.log(count.value);
      } else if (count.value >= sliceSize.value && pos < sliceSize.value) {
        count.value = 0;
        // console.log("drop");
      }
    };
    const openMembers = (id) => {
      gID.value = id;
      memberQuery.value = "";
      count.value = 0;
      showMembers.value = true;
    };

    const goBack = () => {
      showMembers.value = false;
      ctx.emit("close-chat");
    };

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,

      // members
      gID,
      showMembers,
      openMembers,
      filteredMembers,
      memberQuery,
      searchedMembers,
      goBack,
      scrollHandle,
      sliceSize,
      count,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    };
  },

  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    openOutbox() {
      this.$router.push("/outbox");
    },
    backChat() {
      this.$router.push("/send-messages");
    },
    hideSideBar() {
      this.visibility = false;
    },
  },
};
</script>

<style scoped lang="css">
/* .chat-fixed-search {
  display: block;
} */
</style>
